
import { quoteMapActions, quoteMapState } from "@/store/modules/quote";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

import Vue from "vue";
export default Vue.extend({
  name: "rawXMLViewer",
  components: { CustomAlert },
  data() {
    return {
      rawXML: "",
      error: false
    };
  },
  mounted() {
    this.getXMLHandler();
  },
  methods: {
    ...quoteMapActions(["getSendingOrReceivingXML"]),
    async getXMLHandler() {
      if (!this.ratingId || !this.responseType) {
        this.error = true;
        return;
      }
      try {
        const response = await this.getSendingOrReceivingXML({
          rateId: this.ratingId,
          responseType: this.responseType as any
        });
        const { rawXML } = response.data;
        if (rawXML) {
          this.rawXML = rawXML;
        }
      } catch (error) {
        this.error = true;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest"]),
    responseType(): string | undefined {
      const key: any = this.$route.params.responseTypeLabel;
      if (!key) {
        return undefined;
      }
      const mapping: Record<string, any> = {
        sending: "VS",
        receiving: "VR"
      };
      return mapping[key];
    },
    ratingId(): string {
      return this.$route.params.ratingId;
    }
  }
});
